// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@media print {
  html,
  body {
    display: none; /* hide whole page */
  }
}
table,
tr,
td {
  padding: 3px;
}

// ==============================|| TABLE SEARCH ||============================== //
.input-search {
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  background-color: #f3f3f3;
  width: 100%;
  border-radius: 8px;
  padding-left: 8px;
  input[type='text'] {
    width: calc(100% - 40px);
    background: transparent;
    border: none;
    outline: none;
  }
  &:focus-within {
    border: 1px solid $primaryMain;
  }
  .reset {
    width: 40px;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

.pertanyaaanCard {
  font-size: 12px;
  padding: 5px;
  background-color: #fff;
  height: 140px;
  overflow-y: auto;
  scrollbar-width: thin;
  b,
  strong {
    font-weight: bold;
  }
}
//circle number quiz
.circleContainer {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 3px;
  display: flex;
  justify-content: center;
  width: auto;
  align-items: center;
  flex-wrap: wrap;
  .circleNomor {
    width: 30px;
    height: 30px;
    line-height: 30px;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    background-color: gray;
    text-align: center;
    display: block;
    cursor: pointer;
    position: relative;
    font-size: 13px;
    margin: 5px;
  }
}
.pertanyaaanBox {
  font-size: 1rem;
  padding: 5px;
  overflow-y: auto;
  scrollbar-width: thin;
  b,
  strong {
    font-weight: bold;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
}

.jawabanBox {
  font-size: 1rem;
  padding: 5px;
  b,
  strong {
    font-weight: bold;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
}

.ql-container {
  scrollbar-width: thin;
  min-height: 80px;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}
